export type FirebaseRemoteConfigValues = {
  tasks_api_loading_enabled_web: boolean;
  signup_enabled_web: boolean;
};

export const defaultFirebaseRemoteConfigValues: () => FirebaseRemoteConfigValues = () => ({
  tasks_api_loading_enabled_web: false,
  signup_enabled_web: false,
});
export type FirebaseRemoteConfigValueKeys = keyof FirebaseRemoteConfigValues;

