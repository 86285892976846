
export class SessionStorageUtilities {
  static hasAccess(): boolean {
    try {
      if (window.sessionStorage && typeof window.sessionStorage !== "undefined") {
        return true;
      }
    } catch { }
    return false;
  }

  static getItem(key: string) {
    if (this.hasAccess()) return sessionStorage.getItem(key);
    return null;
  }
  static removeItem(key: string) {
    if (this.hasAccess()) return sessionStorage.removeItem(key);
  }
  static setItem(key: string, value: string) {
    if (this.hasAccess()) return sessionStorage.setItem(key, value);
    return null;
  }
}