import { getAuth, getIdToken } from "firebase/auth";
import axios, { AxiosInstance } from "axios";

let instance: AxiosInstance | undefined = undefined;

export const propelyApi = () => {
  const propelyApiUrl = process.env.REACT_APP_API_URL;
  if (instance) return instance;
  instance = axios.create({
    baseURL: propelyApiUrl,
  });

  // Add an interceptor to add the token to the request
  instance.interceptors.request.use(async (config) => {
    // get id token from firebase modular api
    const user = getAuth().currentUser;
    if (!user) {
      return config;
    }
    const token = await getIdToken(user, false);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};

export const resetPropelyApi = () => {
  console.log("Resetting propelyApi");
  instance = undefined;
};

