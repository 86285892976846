import { Analytics } from "firebase/analytics";
import { getApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { FirebasePerformance } from "firebase/performance";
import { RemoteConfig, fetchAndActivate, getRemoteConfig } from "firebase/remote-config";
import { connectStorageEmulator, getStorage } from "firebase/storage";
import { Loader } from "google-maps";
import i18next from "i18next";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-toastify/dist/ReactToastify.css";
import { defaultFirebaseRemoteConfigValues } from "to-be-removed/api/remoteconfig/FirebaseRemoteConfig";
import { SupportedLanguages } from "./libs/i18next/language";

export class AppData {
  static perf: FirebasePerformance | undefined = undefined;
  static analytics: Analytics | undefined = undefined;
  static loader: Loader | undefined = undefined;

  static setupEmulators() {
    var db = getFirestore();
    connectFirestoreEmulator(db, "localhost", 8080);

    var auth = getAuth();
    connectAuthEmulator(auth, "http://localhost:9099");

    const storage = getStorage();
    connectStorageEmulator(storage, "localhost", 9199);

    const functions = getFunctions(getApp());
    connectFunctionsEmulator(functions, "localhost", 5001);
  }

  static setupLocale() {
    const langId = i18next.language;

    registerLocale(langId, SupportedLanguages.getLanguageWithCode(langId).locale);
    setDefaultLocale(langId);
  }

  static getGoogleMapsAPIKey(): string {
    return getApp().options.apiKey ?? "";
  }

  static getGoogleMapsLoader(): Loader {
    if (this.loader) return this.loader;

    const loader = new Loader(getApp().options.apiKey ?? "", {
      libraries: ["places"],
    });
    loader.load();
    return loader;
  }

  private static config: RemoteConfig;
  static getRemoteConfig(): RemoteConfig {
    if (this.config) return this.config;

    const remoteConfig = getRemoteConfig();
    remoteConfig.defaultConfig = defaultFirebaseRemoteConfigValues();
    fetchAndActivate(remoteConfig);
    this.config = remoteConfig;
    return remoteConfig;
  }
}

