import { UserData } from "@src/to-be-removed/LocalData/User/UserData";
import * as Sentry from "@sentry/browser";

export const setSentryUser = (user: UserData | null) => {
  if (!user) {
    Sentry.setUser(null);
    return;
  }

  Sentry.setUser({
    id: user.id,
    email: user.email,
    username: user.name,
  });
};
