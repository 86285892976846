import * as Sentry from "@sentry/react";
import { getAnalytics } from "firebase/analytics";
import { getApps, initializeApp } from "firebase/app";
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from "firebase/app-check";
import { signOut as firebaseSignOut, getAuth } from "firebase/auth";
import { getPerformance } from "firebase/performance";
import { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { QueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import { AppData } from "./AppData";
import { resetPropelyApi } from "./libs/firebase/api";
import { initI18n } from "./libs/i18next/i18n";
import { setSentryUser } from "./libs/sentry/sentry";
import { SessionStorageUtilities } from "./utils/sessionStorageUtilities";
import "./wdyr";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5, //time before data is considered stale
      cacheTime: 1000 * 60 * 60, //time before inactive queries are garbage collected
      retry: 3,
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000),
    },
  },
});

export const FORCE_PRODUCTION = false;
export const USING_EMULATORS = false;
export const IS_PRODUCTION = FORCE_PRODUCTION || (process.env.NODE_ENV === "production" && process.env.REACT_APP_USING_SANDBOX !== "true");

export function initSentry() {
  if (!process.env.REACT_APP_RUNNING_LOCALLY && !process.env.CYPRESS) {
    Sentry.init({
      dsn: "https://48714bb28597254456c2db3d19ec0227@o4507033354305536.ingest.de.sentry.io/4507231123079248",
      integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
      tracesSampleRate: 1.0,
      tracePropagationTargets: ["localhost", /^https:\/\/propely\.no/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      environment: process.env.REACT_APP_ENVIRONMENT,
    });
  }
}

if (getApps().length === 0) {
  const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  };

  const firebaseApp = initializeApp(firebaseConfig);

  const appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_SITE_KEY!),
    isTokenAutoRefreshEnabled: true,
  });

  if (USING_EMULATORS) {
    AppData.setupEmulators();
  }
  AppData.setupLocale();
  AppData.analytics = getAnalytics(firebaseApp);
  AppData.perf = getPerformance(firebaseApp);
}

const main = async () => {
  // Load translations first
  await initI18n({
    loadPath: "/i18n/{{lng}}/{{ns}}.json",
    debug: false,
    returnNull: false,
  });

  initSentry();

  // Import the components only AFTER the translations are loaded. Importing components before the
  // translations are ready will result in missing strings on the UI
  const App = lazy(() => import("./App"));

  const { Popup } = await import("./components/deprecated/Popup/Popup");
  const { PopupRootContainer } = await import("./components/deprecated/PopupRoot/PopupRoot");

  const domNode = document.getElementById("root");
  if (domNode === null) throw new Error("Root element not found");
  const root = createRoot(domNode);
  root.render(
    <Suspense>
      <App />
    </Suspense>
  );

  const popupNode = document.getElementById(Popup.popup_div_id);
  if (popupNode === null) throw new Error("Popup element not found");
  const popup = createRoot(popupNode);
  popup.render(<PopupRootContainer />);

  const toastNode = document.getElementById("toast-container");
  if (toastNode === null) throw new Error("Toast element not found");
  const toast = createRoot(toastNode);
  toast.render(<ToastContainer />);
};

main();

export const signOut = () => {
  const auth = getAuth();

  firebaseSignOut(auth)
    .then(() => {
      // Sign-out successfully.
      resetPropelyApi();
      if (SessionStorageUtilities.hasAccess()) sessionStorage.clear();
      setSentryUser(null);
      window.location.href = "/signin";
    })
    .catch((error) => {
      console.error("Error signing out");
      console.error(error);
    });
};
