import nb from "date-fns/locale/nb";
import enGB from "date-fns/locale/en-GB";
import i18next, { t } from "i18next";

export type LanguageCode = "en" | "no";

// Change language
export async function changeLanguage(lng: LanguageCode) {
  await i18next.changeLanguage(lng);
  window.location.reload();
}

/**
 * @deprecated Use getNormalizedLanguageCode instead
 */
export const getNormalizedLanguageCode = (fullLanguageCode: string) => {
  const code = fullLanguageCode.substring(0, 2).toLowerCase();
  if (code === "nb") return "no";
  return code;
};

const normalizeLanguageCode = (fullLanguageCode: string) => {
  const code = fullLanguageCode.substring(0, 2).toLowerCase();
  if (code === "nb") return "no";
  return code;
};

export const getCurrentNormalizedLanguageCode = () => {
  return normalizeLanguageCode(i18next.language);
};

export class SupportedLanguages {
  static readonly NO = new SupportedLanguages("no", "🇳🇴", nb);
  static readonly EN = new SupportedLanguages("en", "🇬🇧", enGB);
  static readonly ALL_LANGUAGES = [SupportedLanguages.NO, SupportedLanguages.EN];

  code: LanguageCode;
  flag: string;
  locale: Locale;

  constructor(code: LanguageCode, flag: string, locale: Locale) {
    this.code = code;
    this.flag = flag;
    this.locale = locale;
  }

  static getLanguageWithCode(code: any) {
    if (typeof code === "string") {
      const standardized = getNormalizedLanguageCode(code);
      const foundLang = this.ALL_LANGUAGES.find((lang) => lang.code === standardized);
      if (foundLang) return foundLang;
    }
    return SupportedLanguages.EN;
  }

  getName() {
    return t("language." + this.code);
  }
}

